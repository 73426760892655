
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html{

  font-size: 16px;
}
p{
	line-height: 1.5;
	word-break:keep-all;
	color:#1f283a;
}
button{
	outline: none;
}

html,
body{
	width: 100%;
	height: 100%;
}

body{
 overflow-x: hidden;
}


* {
	font-family: 'NanumSquare', sans-serif !important;
}
 /* 여기까지 css 초기화 */

 @import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css);

 

 body		{ font-family: 'NanumSquare', sans-serif }
.normal		{ font-weight: 400 }
.bold		{ font-weight: 700 }
.bolder		{ font-weight: 800 }
.light		{ font-weight: 300 }


 :root {
	--main-color: #003E9C;
	--sub-color: #14A5D2;
	--btn-color: #00ccff;
  }

  @media (max-width:576px) {
	html{
		font-size: 14px;
	  }
  }





  /* 메인 팝업 배너 */

  .banner-popup-back{
	display: block;
	position: fixed;
	width: 600px;
	top: 3%;
	left: 2%;	
	background-color: #003E9C;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 32;
	color: white;
	box-shadow: 0 6px 16px 0 rgb(0 14 48 / 28%), 0 3px 6px -4px rgb(0 4 41 / 43%), 0 9px 28px 8px rgb(1 0 49 / 20%);
	border-radius: 8px;
}

.banner-popup-close{
	display: none;
	transition: all 0.2s;
}

  .banner-popup-back>div{
	display: flex;
	align-items: center;
	justify-content: center;
  }



.banner-popoup-content{
	position: relative;
}

.banner-detaile-container{
	position: absolute;
	bottom: 42px;
	height: 40px;
	display: flex;
	column-gap: 16px;
}

.btn-banner-detaile{
	height: 40px;
	width: 268px;
}

.show-today-button {
	padding: 8px 10px;
	background-color: transparent;
	color: #fff;
	border: none;
	border-radius: 2px;
	cursor: pointer;
	font-size: 14px;
  }
  
  .show-today-button:hover {
	background-color: rgba(255, 255, 255, 0.2);
  }
  


@media (max-width:768px) {
	.banner-popup-back{
	display: none;
}
  }
  



