
.ant-table-wrapper .ant-table-thead >tr>th,.ant-table-wrapper .ant-table-thead >tr>td {
    position: relative;
    color: #6C7893;
    font-weight: 500;
    text-align: start;
    background: #E5EAF3;
    border-bottom: 0px solid #f0f0f0;
    transition: background 0.2s ease;
    font-size: 0.95rem;
}

.ant-table-wrapper .ant-table-tbody >tr >td {
    transition: background 0.2s, border-color 0.2s;
    border-bottom: 1px solid #EDF0F6;
    font-size: 0.95rem;
    word-break: keep-all;
}

.ant-table-tbody >tr>td:first-child, 
.ant-table-tbody >tr>td:last-child{
    color:#6C7893;
    font-size: 0.88rem;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-start-start-radius: 0px;
    width: 20%;
}
.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
    border-start-end-radius: 0px; 
    width: 20%;
}

.ant-table-wrapper .ant-table-cell, .ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper .ant-table-tbody>tr>th, .ant-table-wrapper .ant-table-tbody>tr>td, .ant-table-wrapper tfoot>tr>th, .ant-table-wrapper tfoot>tr>td {
    position: relative;
    padding: 20px 16px;
    overflow-wrap: break-word;
}


.ant-table-cell > a {
    display: inline-block;
    width: 100%;
    color:inherit;
    padding: 20px 0;
    margin: -20px 0;
}

.ant-table-cell > a:active{
    color:var(--main-color);
}

.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, .ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    width: 1px;
    height: 1.6em;
    background-color: #e5eaf3;
    transform: translateY(-50%);
    transition: background-color 0.2s;
    content: "";
}

.ant-Pagenation{
    text-align: center;
    margin-top: 6vh;
}


.ant-btn-default {
    background: #ffffff;
    border-color: white;
    color: rgb(0, 0, 0);
    box-shadow: none ;
}

.ant-btn {
    font-size: 0.94rem;
    line-height: 1.5714285714285714;
    height: auto;
    padding: 4px 20px;
    border-radius: 2px;
    font-weight: 600;
    min-width: 100px;
}

.ant-btn-primary {
    color: #fff;
    background: var(--sub-color);
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0);
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    background: #13b0e0;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
    color: #fff;
    background: #089ac8;
}

.ant-btn:not(:disabled):focus-visible {
    outline: 0px solid #91caff;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
}


.ant-dropdown .ant-dropdown-menu, 
.ant-dropdown-menu-submenu .ant-dropdown-menu {
    padding: 4px;
    list-style-type: none;
    background-color: #ffffff;
    background-clip: padding-box;
    border-radius: 0;
    outline: none;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}



.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item, 
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
    clear: both;
    margin: 0;
    padding: 8px 20px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5714285714285714;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 0px;
    height: 52px;
}

.ant-dropdown-menu-item-only-child  a{
    display: flex;
    align-items: center;
    column-gap: 60px;
    justify-content: space-between;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
    background-color: rgb(18 165 210 / 8%);
    color: var(--main-color);
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: var(--main-color);
    border-color: white;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    text-align: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}


.ant-modal .ant-modal-content {
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 40px 48px;
}

.ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 8px 12px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
    line-height: 1.5714285714285714;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 2px;
    transition: all 0.2s;
}

.ant-modal-root .ant-modal-wrap {
    position: fixed;
    inset: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;

}

.ant-modal-confirm .ant-modal-confirm-paragraph {
    display: flex;
    flex-direction: column;
    flex: none; 
    row-gap: 8px;
}

.ant-modal-confirm .ant-modal-confirm-body >.anticon {
    flex: none;
    font-size: 28px;
    margin-inline-end: 0px;
    margin-top: 0;
    margin-bottom: 16px;
}

.ant-modal-confirm .ant-modal-confirm-content {
    color: rgba(0, 0, 0, 0.88);
    font-size: 0.94rem;
    line-height: 1.5714285714285714;
    font-weight: 600;
}

.ant-modal-confirm .ant-modal-confirm-body {
    display: flex;
    flex-wrap: nowrap;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.ant-modal-root .ant-modal {
    box-sizing: border-box;
    margin: 16px auto;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    pointer-events: none;
    position: relative;
    top: 0;
    bottom: 0;
    width: auto;
    max-width: calc(100vw - 32px);
}

@media (max-width:420px) {

.ant-modal-root .ant-modal {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1.5714285714285714;
        list-style: none;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        pointer-events: none;
        position: relative;
        top: 0;
        bottom: 0;
        width: auto;
        max-width: 100vw;
    }

    
}

@media (max-width: 767px) {
.ant-modal-root .ant-modal {
        max-width: calc(100vw - 16px);
        margin: 8px auto;
    }
}

.ant-modal .ant-modal-footer {
    text-align: center;
    background: transparent;
    margin-top: 12px;
    padding: 0;
    border-top: none;
    border-radius: 0;
}

.ant-modal .ant-modal-close{
    position: absolute;
    top: 12px;
    inset-inline-end: 12px;
    z-index: 1010;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border-radius: 2px;
    width: 32px;
    height: 32px;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s;
}

.ant-drawer .ant-drawer-body {
    flex: 1;
    min-width: 0;
    min-height: 0;
    padding: 0px;
    overflow: auto;
}

.ant-menu-light.ant-menu-root.ant-menu-inline,.ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline, .ant-menu-light.ant-menu-root.ant-menu-vertical, .ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical{
    border-inline-end: 0px solid rgba(5, 5, 5, 0.06);
}





.ant-menu-light .ant-menu-item-selected, 
.ant-menu-light>.ant-menu .ant-menu-item-selected {
    color: var(--main-color);
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected {
    background-color: transparent;
}


.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title,
.ant-menu-light>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #1f283a;
}

.ant-menu .ant-menu-item, 
.ant-menu .ant-menu-submenu, 
.ant-menu .ant-menu-submenu-title {
    border-radius: 0;
}


.ant-menu {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #1f283a;
    font-size: 1.3rem;
    font-weight: 900;
    line-height: 0;
    list-style: none;
    font-family: "'NanumSquare', sans-serif ";
    margin-bottom: 0;
    padding-inline-start: 0;
    outline: none;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.ant-menu-sub {
    font-size: 1rem;

}


.ant-menu-light .ant-menu-item:nth-child(5).ant-menu-item-selected, 
.ant-menu-light>.ant-menu .ant-menu-item:nth-child(5).ant-menu-item-selected {
    color: #1f283a;
}

.ant-menu-light .ant-menu-item:nth-child(5).ant-menu-item-selected:hover, 
.ant-menu-light>.ant-menu .ant-menu-item:nth-child(5).ant-menu-item-selected:hover {
    background-color: rgba(0, 0, 0, 0.06);
}

.ant-menu-light .ant-menu-item:nth-child(5).ant-menu-item-selected:active, 
.ant-menu-light>.ant-menu .ant-menu-item:nth-child(5).ant-menu-item-selected:active {
    background-color: #e6f4ff;
}

.ant-menu-sub  li,
.ant-menu-sub .ant-menu-item
{
    padding: 0 34px !important;
}

.ant-menu-inline .ant-menu-item {
    height: 60px;
    line-height: 60px;
    list-style-position: inside;
    list-style-type: disc;
}

.ant-menu-inline >.ant-menu-item, 
.ant-menu-vertical >.ant-menu-item,
.ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title, 
.ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
    height: 60px;
    line-height: 60px;
    padding-inline: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-inline: 0px;
    margin-block: 0px;
    width: calc(100% - 0px);
}

.ant-menu-title-content>a{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    justify-content: space-between;
}

.ant-drawer-right>.ant-drawer-content-wrapper {   
    width: 340px !important ;
}

@media (max-width: 576px) {

    .ant-drawer-right>.ant-drawer-content-wrapper {   
        width: 100% !important;
    }
}

.ant-drawer .ant-drawer-header{
    display: flex;
    flex: 0;
    align-items: center;
    padding: 20px 24px;
    font-size: 16px;
    line-height: 1.5;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.ant-drawer .ant-drawer-extra {
    flex: none;
    display: flex;
}

.Drawer-btn{
    background: transparent;
    text-align: left;
    width: 100%;
    height: 60px;
    border: 0;
    margin: 0;
    padding: 0;
    color: #1f283a;
    font-size: 1.3rem;
    font-weight: 900;
    line-height: 0;
    list-style: none;
    font-family: "'NanumSquare', sans-serif ";
    margin-bottom: 0;
    padding-inline-start: 0;
    outline: none;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.ant-radio-button-wrapper:first-child {
    border-inline-start: 1px solid #878787;
    border-start-start-radius: 2px;
    border-end-start-radius: 2px;
}
.ant-radio-button-wrapper:last-child {
    border-start-end-radius: 2px;
    border-end-end-radius: 2px;
}

.ant-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding-inline: 15px;
    padding-block: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 1rem;
    font-weight: 900;
    line-height: 30px;
    background: #ffffff;
    border: 1px solid #CCCCCC;
    border-block-start-width: 1.02px;
    border-inline-start-width: 0;
    border-inline-end-width: 1px;
    cursor: pointer;
    transition: color 0.2s, background 0.2s, box-shadow 0.2s;
}

.ant-radio-button-wrapper:hover {
    position: relative;
    color: var(--main-color);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: var(--main-color);
    border-color: var(--main-color);
}

.ant-radio-group-solid :where(.css-dev-only-do-not-override-1kuana8).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: var(--main-color);
    border-color: var(--main-color);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: var(--main-color);
}
.ant-draw-close{
    position: absolute;
    top: 12px;
    inset-inline-end: 12px;
    z-index: 1010;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border-radius: 0px;
    width: 32px;
    height: 32px;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s;
}

.ant-draw-close:hover{
    color: rgba(0, 0, 0, 0.88);
    background-color: rgba(0, 0, 0, 0.06);
    text-decoration: none;
}

.ant-draw-close:active{
    background-color: rgba(0, 0, 0, 0.15);
}

.ant-drawer .ant-drawer-title {
    flex: 1;
    margin: 0;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    display: flex;
    align-items: center;

}
.ant-tabs-top >.ant-tabs-nav,
.ant-tabs-bottom >.ant-tabs-nav, 
.ant-tabs-top >div>.ant-tabs-nav,
.ant-tabs-bottom >div>.ant-tabs-nav {
    margin: 0 0 40px 0;
}

.ant-tabs-top >.ant-tabs-nav::before,
.ant-tabs-bottom >.ant-tabs-nav::before,
.ant-tabs-top >div>.ant-tabs-nav::before, 
.ant-tabs-bottom >div>.ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0;
    content: '';
}


.ant-tabs .ant-tabs-tab {
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-flex;
    align-items: center;
    padding: 0px 10px;
    font-size: 0.98rem;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: #6C7893;
    font-weight: 700;
}

.ant-tabs .ant-tabs-tab:hover {
    color: black;
}

.ant-tabs-tab .ant-tabs-tab-active{
    border: #14A5D2;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #14A5D2;
    text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: #14A5D2;
    pointer-events: none;
}

.ant-input-outlined:hover {
    border-color: var(--main-color);
    background-color: #ffffff;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
    border-color: var(--main-color);
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
    background-color: #ffffff;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 30px 0 0 0;
}
.ant-checkbox .ant-checkbox-inner {

    width: 20px;
    height: 20px;
    border-radius: 2px;
    border:1px solid #c8c8c8;
}
.ant-checkbox .ant-checkbox-inner:after{ 
    box-sizing: border-box;
    position: absolute;
    top: 48%;
    inset-inline-start: 28%;
    display: table;
    width: 7px;
    height: 11px;
    border: 2px solid #fff;
    border-top: 0;
    border-inline-start: 0;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    content: "";
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
}
.ant-checkbox-checked .ant-checkbox-inner:after {
    opacity: 1;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1450b4;
    border-color: #1450b4;
}