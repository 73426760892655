

.container-100{   
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.Product-back{
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
}

.Logo-container{
    display: flex;
    align-items: flex-end;
}

.Logo-text{
    font-size: 1rem;
    color: var(--main-color);
    font-weight: 600;
    margin-bottom: 2px;
    margin-left: 6px;
}

.Product-container{
    max-width: 800px;
    padding: 120px 0;
    margin: 0 6vh;
}

.Product-title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2vh 0;
    column-gap: 70px;
    
}

.Product-title{
     font-size: 2rem;
     font-weight: 800;
     line-height: 1.3;
}

.Product-logo{

    text-align: right;
}

.Product-logo-text{
 font-size: 0.65rem;
 font-weight: 600;
}
.Product-image-container{
    display: flex;
    justify-content: center;
}

.Product-image{
    width: 100%;
    max-width: 800px;
    height: auto;
    object-fit: contain;
}

.Product-text-Inno{
    font-size: 1.38rem;
    color: var(--sub-color);
    text-align: center;
    margin: 0 0 10vh 0;
    font-weight: 800;
}

.Product-text-container{
    margin: 10vh 0 2vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Product-text{
    font-size: 2.2rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
    max-width: 700px;
    line-height: 1.5;
    color:#1f283a;
    
}

.Text-point{
    color:var(--sub-color);
    font-weight: 800;
}
.Text-normal{
    font-weight: 800;
}

.Text-point-MMG{ 
    font-size: 2.2rem;
    color: #E4007F;
    font-weight: 800;
    margin-bottom: 30px;
    text-align: center;
} 
.Finding-container{
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    justify-content: space-between;
    
}
.Finding-group{
    display: flex;
    column-gap: 10px; 
    row-gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
}


.Finding{
 flex: 30%;
 display: flex;
 justify-content: center;
 align-items: center;
 color: var(--main-color);
 font-size: 1.25rem;
 height: 60px;
 background: rgba(25, 70, 160, 0.1);
 font-weight: 800;
 text-align: center;
 text-overflow: ellipsis;
 padding: 0 8px;

}

.NoFinding{
    flex:1;
    height: 60px;
   }

.Finding100{
    flex: 66%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--sub-color);
    font-size: 1.25rem;
    height: 60px;
    background-color: rgba(0, 160, 200, 0.2);
    font-weight: 800;
}

.Product-desc-container{
    display: flex;
    column-gap: 12px;
    margin-top: 30px;

}

.Product-desc-container{
    display: flex;
    column-gap: 12px;
    margin-top: 30px;

}

.Product-item-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;


}

.FindingMMG{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E4007F;
    font-size: 1.25rem;
    height: 60px;
    background: rgba(228, 0, 127, 0.1);
    font-weight: 800;
   }

   .W-fai{
    width: 100%;
    background: rgba(25, 70, 160, 0.1);
    padding: 4.5vh 0;
    text-align: center;
    color: var(--main-color);
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 30px;
   }

 .W-fai-title{
    color: var(--main-color);
    line-height: 1.2 ;
    margin: 0 10px;
   }

.W-fai-title-en{
    color: var(--main-color);
    line-height: 1.2 ;
    margin: 0 10px;
    min-height: 116px;
   }


   .Text-c{
    text-align: center;
   }
   .hidden{
    display: none;
   }

   .min-show768,
   .min-show576{
    display: none;
   }


   @media (max-width:768px) {
    .Product-logo{
        display: none;
    }
    .min-hidden768{
        display: none;
    }
    .min-show768{
    display: block;
   }
   .Logo-container {
    justify-content: center;
    }
    .Product-title-container {
        justify-content: center;
    }
    .Product-title {
        text-align: center;
    }
    .Product-desc-container {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    }

    .Product-item-container {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    }
    .W-fai {
        margin-bottom: 15px;
    }

    .W-fai-title-en{
        min-height: auto;
       }


   }
   @media (max-width:576px) {
    .Text-point-MMG,
    .Product-text{
        font-size: 1.9rem;
        margin-bottom: 0px;
    }
    .min-show576{
        display: block;
    }
    .min-hidden576{
        display: none;
   }
   
   .Product-container {
    padding: 60px 0;
    }

   }

   .eBrochure{
    height: 100%;
   }