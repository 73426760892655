
@import url(~slick-carousel/slick/slick-theme.css);
@import url(~slick-carousel/slick/slick.css);
@import url(react-responsive-carousel/lib/styles/carousel.min.css); 


.App {
	width: 100%;
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Header-container {
	width: 100%;
	 display: flex;
	 justify-content: center;
	 box-shadow: 0px 4px 8px 0px rgba(73, 88, 118, 0.15);
	 background-color: white;
	 position: sticky;
	 top:0;
	 z-index: 30;
     min-height: 64px;
   
   }
   
.App-header {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 6vw;

}

.Header-nav{
	display: flex;
	column-gap: 3vw;
    align-items: center
}

.Header-nav > a{
	font-size: 1.05rem;
	text-decoration: none;
	color:#1f283a;
	font-weight: 600;
    padding: 8px 15px;
    height: 100%;
    
}

.Header-nav > a:hover{
    color: var(--main-color);
}


.Header-nav  button{
	font-size: 1.05rem;
	text-decoration: none;
	color: black;
	font-weight: 600;
}

.Header-menu{
	display: flex;
	column-gap: 1vw;
	align-items: center;
}

.FillButton{
	min-width: 300px;
    background: var(--sub-color);
    font-size: 1.1rem;
    color: #ffffff;
    font-weight: 800;
    text-decoration: none;
    display: flex;
    border: 1px solid var(--sub-color);
    border-radius: 2px;
    text-align: center;
    justify-content: center;
    padding: 12px 0;
}

.LinefullButton{
	min-width: 300px;
	background: white;
	font-size: 1.1rem;
    color: var(--sub-color);
    font-weight: 800;
    text-decoration: none;
    display: flex;
    border: 1px solid var(--sub-color);
    background: white;
    border-radius: 2px;
	text-align: center;
}


.LineButton{
	min-width: 300px;
    text-decoration: none;
	background: transparent;
	border: 1px solid var(--sub-color);
	color: var(--sub-color);
	font-size: 1.1rem;
	font-weight: 800;
	padding: 12px 0px;
	border-radius: 2px;
	text-align: center;
}




.Footer-back{
	width: 100%;
	display: flex;
	padding: 5vh 0 4vh 0;
	background: var(--main-color);
	justify-content: center;
}



.Footer-container{
    display: flex;
    width: 100%;
	max-width: 1200px;
    margin: 0 6vw;
    flex-direction: column;
    color:white;
}

.Footer-container  p{
    color:white;
}

.Footer-top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.banner-footer p{
 color:#003E9C;
}

.Sns-container{
    display: flex;
    align-items: center;
    column-gap: 30px;
}

.Footer-bottom{
    display: flex;
    justify-content: space-between;
    align-items: end;

}

.container-100{   
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.PlatformList-container-back{
    padding: 14vh 0;
    background: #e7ebf3;
    background: linear-gradient(0deg, #ffffff 0%, #e7ebf3b3 100%); 
    padding: 16vh 0 8vh 0;
}



.PlatformList-container{
    max-width: 1200px;
    display: flex;   
    flex-wrap: wrap;
   column-gap: 1.4vw;
   row-gap: 10vh;
   justify-content: space-around;
   margin: 0 6vw;
   margin-bottom: 15vh;
}
.PlatformList-title{
    font-size: 2rem;
    margin-bottom: 10vh;
    font-weight: 800;
    color: var(--main-color);
}

.Solution-container{
    position: relative;
    width: 90%;
    text-align: center;
    transition: all 1s;

}

.Solution-image-container:hover{
    transform: translate(0, -14px);
}

.Solution-image-container{
  display: flex;
  margin-bottom: 28px;
  transition: all 0.8s;
  justify-content: center;

}

.Solution-image{
   flex-grow: 1;
   width: 100%;
   max-width: 400px;
    border-radius: 2px;
    /* box-shadow: 0px 2px 10px 0px rgba(60, 65, 106, 0.2); */
}
.Solution-title-container{
    display: flex;   
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    column-gap: 10px;
}

.Solution-title{
    font-size: 1.05rem;
    font-weight: 800;
    line-height: normal;
    padding: 0;
    
   
}

.Solution-description{
    font-size: 0.9rem;
    color:#0d131e;
}



.Badge-text{
    background: rgb(20 165 210 / 20%);
    font-size: 0.8rem;
    font-weight: 700;
    text-align: center;
    padding: 6px 0;
    color: #00799e;
    max-width: 160px;
    margin: auto;
    margin-top: 8px;
   
}





.Introduce-container-back{
    width: 100%;
    Padding : 16vh 0 6vh 0;
    text-align: center;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.Introduce-content-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 940px;
    margin: 0 6vw;
}

.Introduce-title{
    margin-top: 80px;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 900;
    color: var(--main-color);
}

.Introduce-description{
    margin-top: 34px;
    font-size: 1.2rem;
    text-align: left;
    line-height: 2;
    max-width: 1000px;
    text-align: center;
    font-weight: 500;
}


.Introduce-detail-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    margin: 0 6vw;
    padding: 0 0 22vh 0;
    row-gap: 16vh;
    overflow-x: hidden;
}

.Introduce-detail-content{
    display: flex;    
    column-gap: 4vw;
    align-items: center;
    overflow-x: hidden;
}

.Introduce-detail-icon{
 display: flex;
 justify-content: center;
 min-width: 200px;
}

.Introduce-detail-text{
 display: flex;
 flex-direction: column;
 justify-content: center;
 flex-grow: 1;
}

.Introduce-detail-title{
font-size: 1.5rem;
font-weight: 800;
color: var(--main-color);
margin-bottom: 12px;
}

.Introduce-detail-description{
 font-size: 1rem;
 line-height: 1.8;

}

.PlatformList-text{
    font-size: 1.15rem;
    margin:0 6vw;
    margin-bottom: 4vh;
    color: var(--sub-color);

}

.PlatformList-brochure{
    font-size: 1.2rem;
    color: white;
    font-weight: 800;
    text-decoration: none;
    display: flex;
    background: var(--sub-color);
    border-radius: 8vh;
    padding: 1.1vh 8vw;
}

.Basic-container{
    max-width: 1200px;
    padding: 14vh 0;
    display: flex;   
    row-gap: 6vh;
   justify-content:flex-start;
   margin: 0 6vw;
}

.Title-container{
    width: 100%;
    text-align: left;
}

.Title{
    font-size: 1.7rem;
    font-weight: 800;
    margin-bottom: 2vh;
}

.Seperator{
 width: 100%;
 height: 1px;
 background: #dde2ee ;
 margin-bottom: 7vh;
}

.Thumb-container{
    max-width: 1200px;
    display: flex;   
    flex-wrap: wrap;
   column-gap: 24px;
   row-gap: 6vh;
   justify-content: flex-start;
}

.News-item-container{
    width: calc(33% - 34px);
    text-align: left;
    border: 1px solid rgb(203 210 225 / 50%);
    border-radius: 4px;
    padding: 8px;
    /* box-shadow: 0px 2px 10px 0px rgba(73, 88, 118, 0.1); */
    height: 400px;
    display: flex;
    flex-direction: column;
    text-decoration: none;  
    color: black;
    transition: all 0.5s;

}

.News-item-container:hover{
    /* box-shadow: 0px 2px 10px 0px rgba(73, 88, 118, 0.2);  */
    /* border: 1px solid rgba(24 70 160 / 20%); */
    box-shadow: 0px 2px 10px 0px rgba(62 90 140 / 14%);
    transform: translate(0, -16px) !important;
}


.News-image{
    flex-grow: 1;
    width: 100%;
    min-height: 174px;
    max-height: 174px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;  
    object-fit: cover;
 }

 .News-text-container{
    display: flex;
    flex-direction: column;
    padding: 20px 16px 16px 16px;
    flex: 1;
    justify-content: space-between;
 }

 .News-title{
    font-size: 1.15rem;
    font-weight: 800;
    color: var(--main-color);
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow:ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2 ;
    -webkit-box-orient: vertical;
 }

 .News-desc{
    font-size: 0.9rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow:ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 4 ;
    -webkit-box-orient: vertical;
 }

 .News-date{
    font-size: 0.85rem;
    color:#70747D;
 }



.News-more-container{
    display: flex;
    justify-content: center;
    margin-top: 6vh;
}


.Scroll-indi-back{
    position: relative;
	width: 100%;
	display: flex;
	padding: 30vh 0;
     background: linear-gradient(0deg, var(--main-color) 0%, rgb(0 58 174) 100%);
    justify-content: center;
}

.Scroll-ai-back{
    max-width: 800px;
    position: absolute;
    height: 100%;
    width: auto;
    object-fit: contain;
    margin-top: -32vh;
    opacity: 0.8;
}

.Scroll-indi-container{
    width: 100%;
	max-width: 1200px;
    margin: 0 6vw;
    display: flex;
    flex-direction: column;
    color:white;
    align-items: center;
    row-gap: 8vh;
}

.Scroll-indi-text{
    font-size: 3rem;
    text-align: center;
    max-width: 50vw;
    color: white;
    font-weight: 600;
}

.Btn-lang{
	border: none;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
    column-gap: 6px;
    font-size: 0.96rem;
    font-weight: 700;
    padding: 4px 8px;
}
.Btn-lang:hover{
    color:var(--main-color);
}

.Btn-contact{
	border: 1.4px solid var(--main-color);
	background: white;
	border-radius: 2px;
	color: var(--main-color);
	font-size: 0.9rem;
	padding: 6px 12px;
	font-weight: 600;
    min-width: 100px

}
.Banner-back{

	width: 100%;
	display: flex;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
    justify-content: center;
    align-items: center;
    
}
.Banner-text-container{
    position: absolute;
    max-width: 1200px;
    display: flex;   
   margin: 0 6vw;
   flex-direction: column;
   justify-content: center;
   z-index: 20;

}
video {
    width: 100%;
    height: 98vh;
    object-fit: cover;
  }



.carousel-container{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.styles-module_item-tracker__3bypy {
    align-items: center;
    padding: 2vh 0;
}

.styles-module_item-container__a8zaY{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PalatformTitle{
    font-weight: 800;
     color: var(--main-color); 
    font-size: 2rem;
    margin: 0 6vw;
    margin-bottom: 40px;
    text-align: center;
}

.styles-module_carousel-arrow__26sRw{
    background-color: transparent;
    width:40px;
    height: 40px;
    border-radius: 50%;
    border: 0px solid rgb(154 165 188 / 40%);
    margin: 120px 0;
    background-position: center;
}

.styles-module_carousel-arrow__26sRw:hover{
    background-color: rgba(255, 255, 255, 0.05);
}

.styles-module_carousel-arrow__26sRw[data-direction=left]
{
    background-image:url(../res/Icon_Arrow_l.png);
}

.styles-module_carousel-arrow__26sRw[data-direction=right]
{
    background-image:url(../res/Icon_Arrow.png);
}

.Palatform-text{
    font-size: 1rem;
    margin: 0 6vw;
    margin-top: 7vh;
    color: var(--sub-color);
    font-weight: 600;
    text-align: center;
}


.Modal-title{
    font-size: 1.8rem;
    font-weight: 800;
    margin-bottom: 8px;
}

.Form-container{
    
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.Modal-submit {
    text-align: center;
    margin: auto;
    width: 100%;
    max-width: 300px;
    background: var(--sub-color);
    font-size: 1.1rem;
    color: white;
    font-weight: 800;
    display: flex;
    justify-content: center;
    border: 0;
    padding: 12px 60px;
    border-radius: 2px;
}



.Drawer-menu{
    position: absolute;
    width: 80vw !important;
}

.Drawer-container{
    display: none;
}

.Banner-text-brShow{
    display: none;
}

@media (max-width : 992px) {

    .Drawer-container{
        display: block;
       }

       .Header-nav, .Header-menu{
        display: none;
       }
       .News-item-container {
        width: calc(50% - 34px);
        text-align: left;
        border: 1px solid rgb(203 210 225 / 50%);
        border-radius: 4px;
        padding: 8px;
        /* box-shadow: 0px 2px 10px 0px rgba(73, 88, 118, 0.1); */
        height: 400px;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: black;
        transition: all 0.5s;
    }
    .Vision-title-br{
        display: none;
    }


}


@media (max-width : 768px) {
    .Introduce-detail-content {
        display: flex;
        flex-direction: column;
        row-gap: 8vw;
        align-items: center;      
        overflow-x: hidden;
        text-align: center;
        justify-content: center;
    }

    .News-item-container {
        width: 100%;
    }

    .Footer-bottom{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        row-gap: 16px;
    
    }
    .Scroll-ai-back{
        display: none;
    }

    .Scroll-indi-text{
        font-size: 2.5rem;
        max-width: none;
    }

    .Scroll-indi-back{
       
        padding: 20vh 0;

    }
    
    .Scroll-ai-back{
        margin-top: -22vh;
        opacity: 0.8;
    }
    .Banner-text-brShow{
        display: block;
    }
    

  

}

.slick-slider {
    width: 90%;
}



  .slick-list { 

    margin: 0 auto;
    overflow-x: hidden;
  }


  .slick-dots {  
    bottom: -20px;
    margin-top: 200px;
  }

  .slick-track { 
    width: 100%;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0; 
    padding: 0;
    cursor: pointer;
}

.slick-slide > div {
    display: flex;
    justify-content: center;
    padding: 30px 0;
}


.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 40%;
    display: block;
    width: 30px;
    height: 30px;
    padding: 0;
    transform: translate(0, -100%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:before, .slick-next:before {
    font-size: 22px;
    line-height: 1;
    opacity: 0.75;
    color: #1f283a;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #303a4e;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 0.75;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #14A5D2;
}
.slick-next:before {
    content: '〉';
}
.slick-prev:before {
    content: '〈';
}

@media (max-width:576px) {
    .LineButton {
        min-width: 240px;
    }
}


.Banner-text-container p{
 color: white;
 font-size: 5rem;
 font-weight: 700;
 margin: 0 10px;
}

.opacity0{
    opacity: 0;
    display: inline-block;
}

.opacity1 {
    opacity: 1;
    display: inline-block;
}

@keyframes textAni{
    0%
   { opacity: 0;
    transform: translateY(-30px);
 
    }
   100%
   {  opacity: 1;
    transform: translateY(0px);
   
    }
}

.text-ani{  
    animation: textAni;
    animation-duration: 2s;  
    animation-fill-mode: forwards;
}

.text-ani1s{
    animation: textAni;
    animation-duration: 2s;
    animation-delay:0.5s;
    animation-fill-mode: forwards; 
}

.text-ani2s{
    animation: textAni;
    animation-duration: 2s;
    animation-delay:1s;
    animation-fill-mode: forwards;

}
  


/* 브로슈어 */

.brochure-container{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
  

}


#root {
    width: 100%;
    height: 100%;
}

.carousel-root,
.carousel.carousel-slider,
.carousel .slider-wrapper,
.carousel .slider,
.carousel .slide,
.carousel .slider div,
.carousel .slide img{
    width: 100%;
    height: 100%;
}

.carousel .slide{
    background-color: #1f1f1f;
}

.carousel .slide img{
    object-fit: contain;
}

/* 이지스 */

.eghis-container{
    width: 100%;
    background-color: white;
    position: absolute;
    top:0;
    z-index: 999999;
}

.eghis01-container{
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eghis-center {
    text-align: center;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 160px;
}
.eghis-img{

    z-index: -1;
    object-fit: cover;
    width: 100%;
    height: inherit;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
}
.eghis02-container,
.eghis03-container
{
    width:100%;
 position: relative;
 display: flex;
 justify-content: center;
 align-items: center;
}


.eghis02-wrap{
    display: flex;
    justify-content:space-between;
    column-gap: 30px;
}

.eghis02-text-wrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    width: 992px;
}
.alignTextRight{

    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.alignTextLeft{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
   
}

.modal-check-container{
    display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
        justify-content: space-between;
        margin-bottom: 30px;
}

.modal-checkSolution {
    width: 22%;
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    position: relative;
    align-items: center;
}

@media (max-width:768px) {
    .modal-checkSolution {
        width: 30%;
    }
}

@media (max-width:576px) {
    .modal-checkSolution {
        width: 45%;
    }
}


