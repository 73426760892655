

.container-100{   
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Vision-container-back{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1A4BAD;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

   
}


.Vision-container{
    display: flex;   
   justify-content: center;
    align-items: center;
   margin: 0 6vw;
   flex-direction: column;
   padding: 20vh 0 20vh 0;
}

@media (max-width:576px) {
    .Vision-container{
        padding: 14vh 0;
    }
    
}

.Vision-title{
    text-align: center;
    font-size: 2.5rem;
    font-weight: 900;
    color: white;
    margin-bottom: 12vh;
    margin-top: 4vh;
}
.Vision-back{
    display: block;
}
.Vision-content-container{
    display: flex;
    align-items: flex-start;
    min-height: 368px;
    flex-direction: column;
    justify-content: space-around;
}
.Vision-text-container{
    margin-left: 400px;
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.left280{
    margin-left: 280px;
}
.Vision-text-container > p {
    color:white;
    line-height: 1.6;
}
.Vision-text-title{
    font-size: 1.6rem;
    font-weight: 800;
    margin-bottom: 0.8vh;
}
@media (max-width:520px) {
    .Vision-text-title{
         max-width: 320px;
    }
    .History-timeline-container{
    flex-direction: column;
    row-gap: 1vh;
    }
}

.Vision-text-detaile{
    font-size: 1.1rem;

}
.History-container-back{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20vh 0;
    background-position: top;
    background-size: cover;
}


.History-container{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.History-content-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.History-content{
    width: 100%;
    max-width: 1200px;
    margin: 0 6vw;
    display: flex;
    flex-direction: column;

}

.History-row{
    flex-direction: column;
    column-gap: 4vw;
    flex-wrap: wrap;
}

.Default-title{
    font-size: 1.6rem;
    font-weight: 800;
}
.Default-seperator{
    width: 100%;
    height: 1px;
    background: #CBD2E1;
    margin: 2vh 0 4vh 0;
}
.History-timeline-back{
    width: 100%;
    margin-top: 5vh;
    background: #F7F9FD;
    padding: 4vh 0;
    background-position: top;
    background-size: cover;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    overflow: hidden;

}
.History-timeline-container{
    max-width: 1200px;
    display: flex;
    /* justify-content: space-between; */
    margin: 6vh 0;
    flex: 1;
    column-gap: 8vw;
}

.History-year{
    font-size: 2rem;;
}

.History-text > p{
    margin-bottom: 6px;
}

.Partner-container-back{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 16vh 0;
}

.partner-container{
    display: flex;
    justify-content: space-around;
    margin: 10vh 0;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 6vh;
    justify-content: flex-start;
    align-items: center;

}
.Partner-logo{
    flex:22%;


}

@media (max-width:992px) {
    .Vision-back{
        display: none;
    }
    .Vision-content-container {
        display: flex;
        align-items: center;
        text-align: center;
    }

    .Vision-text-container {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .left280{
        margin-left: 0;
    }
}

@media (max-width:768px) {
    .Partner-logo{
       flex: 30%;
    }
    /* .History-timeline-container{
        margin: 6vh auto;
    } */

    .History-row {
        flex-direction: column;
        column-gap: 4vw;
        flex-wrap: wrap;
    
    }
}