@import url(../App.css);
@import url(../antd.css);

.container-100{   
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.News-container-back{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12vh;
}


.News-container{
    display: flex;
    width: 100%;
    justify-content: center;
}

.News-content{
    width: 100%;
    max-width: 1200px;
    margin: 0 6vw;
    display: flex;
    flex-direction: column;
}

.News-main-title{
    font-size: 2.3rem;
    font-weight: 800;
    margin-bottom: 6vh;
}

.Default-title{
    font-size: 1.6rem;
    font-weight: 800;
}
.Default-seperator{
    width: 100%;
    height: 1px;
    background: #CBD2E1;
    margin: 10px 0 4vh 0;
}

.Tab-container{
    display: flex;
    column-gap: 20px;
}

.Radio-container{

    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
}

.Radio-tab{
    opacity: 0;
    position: absolute;
    width: 80px;
    height: 50px;
  }

  .Radio-tab + p{
    color: #6C7893;
    padding: 0 10px;
    border-bottom: 2px solid rgba(255, 0, 0, 0);
    font-size: 0.98rem;
    font-weight: 700;

  }
  
  .Radio-tab:hover + p{
    color: #000000;
    border-bottom: 2px solid rgba(255, 0, 0, 0);

    
  }
  
  .Radio-tab:active + p{
    color:var(--sub-color);
    border-bottom: 2px solid #00B6D1;

  }
  
  .Radio-tab:checked + p{ 
    color: var(--sub-color);
    border-bottom: 2px solid var(--sub-color);

  }

  .Video-list-container{
    max-width: 1200px;
    display: flex;   
    flex-wrap: wrap;
   column-gap: 1vw;
   row-gap: 6vh;
   justify-content: space-between;
} 

  .Video-item-container{
    width: calc(33% - 15px);
    text-align: left;
    /* border: 1px solid rgb(203 210 225 / 50%);
    border-radius: 4px; */
    /* box-shadow: 0px 2px 10px 0px rgba(73, 88, 118, 0.1); */
    display: flex;
    flex-direction: column;
    text-decoration: none;  
    color: black;
      position: relative;
      transition: all 0.5s;
}

.Video-item-container:hover{

  transform: translate(0, -16px);
}

.Video-container{
    z-index: 10;
    display: flex;
    flex-direction: column;
}

.Video-title{
    margin-top: 16px;
    font-size: 1.1rem;
    font-weight: 600;
}

.Video-tag{
    color:#7a8191;
    font-size: 0.85rem;
}

@media (max-width:992px) {

  .Video-item-container{
    width: calc(50% - 10px);
    text-align: left;
    display: flex;
    flex-direction: column;
    text-decoration: none;  
    color: black;
    position: relative;
}
  
}
@media (max-width : 768px){
  .Video-item-container{
    width: 100%;
}
}